<template>
  <div>
    <div class="alert alert-light alert-elevate" role="alert" v-if="suggestion !== null">
      <div class="alert-icon alert-icon-middle">
        <i class="flaticon-info kt-font-brand"></i>
      </div>
      <div class="alert-text">
        <p>
        <h5>How do I improve my ADs?</h5>
        <p>{{ suggestion.description }}</p>
        </p>
      </div>
    </div>
    <div class="row flex center">
      <div class="col-md-6 col-lg-3">
        <!--begin: Stats Widget 19-->
        <div class="card card-custom card-rounded bg-light-success card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body my-3">
            <div>
              <h3 class="font-weight-bold font-size-sm" style="display: inline-block">
                <template v-if="stats !== null">
                  <span class="text-primary font-size-h2 font-weight-bolder mr-2">
                    {{ stats.total.account.impression_share.toFixed(0) }}%</span>
                </template>
                <Loader :newStyle="1" v-else :width="100" />
              </h3>
              <span class="font-weight-bold text-muted font-size-sm">Impression score (0 - 100%)</span>
            </div>
            <div class="progress progress-xs mt-7 bg-success-o-60 my-3">
              <template v-if="stats !== null">
                <div
                  :class="`progress-bar bg-${ stats.total.account.impression_share < 60 ? (stats.total.account.impression_share < 20 ? 'danger' : 'warning') : 'success' }`"
                  role="progressbar" :style="
                    `width: ${ stats.total.account.impression_share.toFixed(0) }%;`
                  " :aria-valuenow="stats.total.account.impression_share.toFixed(0)" aria-valuemin="0" aria-valuemax="100">
                </div>
              </template>
              <Loader :newStyle="1" v-else :width="480" />
            </div>
          </div>
          <!--end:: Body-->
        </div>
        <!--end: Stats:Widget 19-->
      </div>
      <div class="col-md-6 col-lg-3">
        <!--begin::Stats Widget 26-->
        <div class="card card-custom card-rounded bg-light-danger card-stretch gutter-b">
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <i class="flaticon-eye text-info" style="font-size: 20px; margin-right: 5px;"></i>
              <template v-if="stats !== null">
                {{ stats.total.account.impressions }}
              </template>
              <Loader :newStyle="1" v-else :width="100" />
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              Views of your ads
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 26-->
      </div>
      <div class="col-md-6 col-lg-3">
        <!--begin: Stats Widget 19-->
        <div class="card card-custom card-rounded bg-light-success card-stretch gutter-b">
          <!--begin::Body-->
          <div class="card-body my-3">
            <div>
              <h3 class="font-weight-bold font-size-sm" style="display: inline-block">
                <template v-if="stats !== null">
                  <span class="text-primary font-size-h2 font-weight-bolder mr-2">
                    {{ (stats.total.account.ctr * 100).toFixed(0) }}%</span>
                </template>
                <Loader :newStyle="1" v-else :width="100" />
              </h3>
              <span class="font-weight-bold text-muted font-size-sm">Click through rate (0 - 100%)</span>
            </div>
            <div class="progress progress-xs mt-7 bg-success-o-60 my-3">
              <template v-if="stats !== null">
                <div
                  :class="`progress-bar bg-${ stats.total.account.impression_share < 80 ? (stats.total.account.impression_share < 30 ? 'danger' : 'warning') : 'success' }`"
                  role="progressbar" :style="
                    `width: ${ (stats.total.account.ctr * 100).toFixed(0) }%;`
                  " :aria-valuenow="(stats.total.account.ctr * 100).toFixed(0)" aria-valuemin="0" aria-valuemax="100"></div>
              </template>
              <Loader :newStyle="1" v-else :width="480" />
            </div>
          </div>
          <!--end:: Body-->
        </div>
        <!--end: Stats:Widget 19-->
      </div>
      <div class="col-md-6 col-lg-3">
        <!--begin::Stats Widget 26-->
        <div class="card card-custom card-rounded bg-light-danger card-stretch gutter-b">
          <!--begin::ody-->
          <div class="card-body my-3">
            <h3 class="text-primary font-weight-bold font-size-sm">
              <i class="flaticon-black text-danger" style="font-size: 20px; margin-right: 5px;"></i>
              <template v-if="stats !== null">
                {{ stats.total.account.clicks }}
              </template>
              <Loader :newStyle="1" v-else :width="100" />
            </h3>
            <div class="font-weight-bold text-muted font-size-sm my-3">
              Clicks of your ads
            </div>
          </div>
          <!--end::Body-->
        </div>
        <!--end::Stats Widget 26-->
      </div>
    </div>
    <div>
      <KTPortlet title="Overview" data-app="ads">
        <template v-slot:toolbar v-if="start !== null && end !== null">
          {{ fstart }} - {{ fend }}
        </template>
        <template slot="body">
          <!-- <div
            style="display: flex; align-items: center; justify-content: space-evenly; margin: 20px; margin-top: 0;"
          >
            <div
              v-if="false"
              class="card"
              style="text-align: center; padding: 10px; background: none; border: none;"
            >
              <span>Searches on your keywords</span>
              <h3 style="margin: 0.5rem;">
                {{ keywordSearches }}
              </h3>
              <span style="font-size: 10px;">Potential views of your ads</span>
            </div>
            <div
              class="card"
              style="text-align: center; padding: 10px; background: none; border: none;"
            >
              <span>Views of your ads </span>
              <h3 style="margin: 0.5rem;">
                <template v-if="stats !== null">
                  {{ stats.account.impressions }}
                </template>
                <Loader :newStyle="1" v-else />
              </h3>
              <span style="font-size: 10px;" v-if="stats !== null"
                >Impression share:
                <b>{{ stats.account.impression_share.toFixed(2) }}%</b></span
              >
            </div>
            <div
              class="card"
              style="text-align: center; padding: 10px; background: none; border: none;"
            >
              <span>Clicks on your ads</span>
              <h3 style="margin: 0.5rem;">
                <template v-if="stats !== null">
                  {{ stats.account.clicks }}
                </template>
                <Loader :newStyle="1" v-else />
              </h3>
              <span style="font-size: 10px;" v-if="stats !== null"
                >Click-Through Rate:
                <b>{{ (stats.account.ctr * 100).toFixed(2) }}%</b></span
              >
            </div>
            <div
              v-if="showConversions"
              class="card"
              style="text-align: center; padding: 10px; background: none; border: none;"
            >
              <span>Conversions</span>
              <h3 style="margin: 0.5rem;">
                <template v-if="stats !== null">
                  {{ stats.account.conversions }}
                </template>
                <Loader :newStyle="1" v-else />
              </h3>
              <span style="font-size: 10px;" v-if="stats !== null"
                >Conversion rate:
                <b
                  >{{
                    (
                      (stats.account.conversions / stats.account.clicks) *
                      100
                    ).toFixed(2)
                  }}%</b
                ></span
              >
            </div>
          </div> -->
          <Loader v-if="
            stats === null ||
            this.$tools.isNullOrUndefined(this.stats.total.account_daily) ||
            this.$tools.isNullOrUndefined(chartData)
          " :inContent="true" :noText="true">Loading...</Loader>
          <template v-else>
            <apexcharts style="color: var(--dark)" width="100%" height="500px" type="area" :options="chartOptions"
              :series="chartData" ref="mainChart"></apexcharts>
          </template>
        </template>
      </KTPortlet>
      <KTPortlet title="Calls" data-app="calls" v-if="stats === null || stats.total.calls.length > 0">
        <template slot="body">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Time</b-th>
                <b-th>Duration</b-th>
                <b-th>Status</b-th>
                <b-th>Type</b-th>
                <b-th>Ad</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="stats === null">
              <b-tr v-for="i in 10" v-bind:key="i">
                <b-th>
                  <Loader :newStyle="1" />
                </b-th>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr v-for="call in stats.total.calls" v-bind:key="`${ call.start_time }${ call.end_time }`">
                <b-th sticky-column>{{ call.start_time }}</b-th>
                <b-td>{{ call.duration }}s</b-td>
                <b-td :style="
                  `${ call.call_status === 'Received'
                    ? 'color: var(--success) !important;'
                    : call.call_status === 'Missed'
                      ? 'color: var(--danger) !important;'
                      : ''
                  }`
                ">{{ call.call_status }}</b-td>
                <b-td>{{ call.call_type }}</b-td>
                <b-td v-if="call.name.indexOf('(Competitors)') >= 0">
                  {{ call.name.split("(")[0].trim() }}
                  <v-chip color="bg-info text-white" class="ma-2" x-small>On Competitor AD</v-chip>
                </b-td>
                <b-td v-else>{{ call.name }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
      <KTPortlet title="ADs" data-app="ads">
        <template slot="body">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th sticky-column>Name</b-th>
                <b-th>Views</b-th>
                <b-th>Clicks</b-th>
                <b-th>Click-Through Rate</b-th>
                <b-th v-if="showConversions">Conversions</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="stats === null">
              <b-tr v-for="i in 10" v-bind:key="i">
                <b-th>
                  <Loader :newStyle="1" />
                </b-th>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td v-if="showConversions">
                  <Loader :newStyle="1" />
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr v-for="(ad, adIndex) in formattedAds" v-bind:key="adIndex">
                <b-th sticky-column v-if="ad.ad_type === 'RESPONSIVE_SEARCH_AD'">
                  <div>
                    {{ ad.headline1 }} | {{ ad.headline2 }}
                    <v-chip v-if="getRelatedAd(ad.headline1, ad.headline2, ad.ad_description) !== null"
                      color="bg-info text-white" class="ma-2" x-small>Your AD: {{ getRelatedAd(ad.headline1,
                          ad.headline2,
                          ad.ad_description).ad.name
                      }} {{getRelatedAd(ad.headline1,
                          ad.headline2,
                          ad.ad_description).old ? ' - An older advert' : ''}}</v-chip>
                  </div>
                  <span style="font-weight: 400">{{ ad.ad_description }}</span>
                </b-th>
                <b-th sticky-column v-else-if="ad.ad_type === 'EXPANDED_DYNAMIC_SEARCH_AD'">
                  <div>
                    Generated ADs
                    <v-chip color="bg-success text-white" class="ma-2" x-small>AI</v-chip>
                  </div>
                  <span style="font-weight: 400">AI based automatic advertising</span>
                </b-th>
                <b-th sticky-column v-else-if="ad.ad_type === 'EXPANDED_TEXT_AD'">
                  <div v-if="ad.headline1.indexOf('KeyWord:') >= 0">
                    {{ ad.headline2 }}
                    <!-- <v-chip color="bg-success text-white" class="ma-2" x-small>AI Generated</v-chip> -->
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip v-bind="attrs" v-on="on" class="ma-2" x-small color="bg-info text-white">{{
                            ad.headline1
                              .split(":")[1]
                              .split("}")[0]
                              .trim()
                        }}</v-chip>
                      </template>
                      <span>Specific keyword based ad</span>
                    </v-tooltip>
                  </div>
                  <div v-else>{{ ad.headline1 }} | {{ ad.headline2 }}</div>
                  <span style="font-weight: 400">{{
                      ad.ad_description
                  }}</span>
                </b-th>
                <b-td v-if="ad.ad_type === 'EXPANDED_DYNAMIC_SEARCH_AD'"><span class="text-success"
                    style="font-weight: 500">+{{ ad.impressions }}</span></b-td>
                <b-td v-else>{{ ad.impressions }}</b-td>
                <!-- <b-td
                    :style="
                      `${
                        call.call_status === 'Received'
                          ? 'color: var(--success) !important;'
                          : call.call_status === 'Missed'
                          ? 'color: var(--danger) !important;'
                          : ''
                      }`
                    "
                    >{{ call.call_status }}</b-td
                  > -->
                <b-td v-if="ad.ad_type === 'EXPANDED_DYNAMIC_SEARCH_AD'"><span class="text-success"
                    style="font-weight: 500">+{{ ad.clicks }}</span></b-td>
                <b-td v-else>{{ ad.clicks }}</b-td>

                <b-td v-if="ad.ad_type === 'EXPANDED_DYNAMIC_SEARCH_AD'"><span class="text-success"
                    style="font-weight: 500">+{{ (ad.ctr * 100).toFixed(0) }}%</span></b-td>
                <b-td v-else>{{ (ad.ctr * 100).toFixed(0) }}%</b-td>

                <template v-if="showConversions">
                  <b-td v-if="ad.ad_type === 'EXPANDED_DYNAMIC_SEARCH_AD'"><span class="text-success"
                      style="font-weight: 500">+{{ ad.conversions }}</span></b-td>
                  <b-td v-else>{{ ad.conversions }}</b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
      <KTPortlet title="Keywords" data-app="keywords">
        <template slot="body">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Name</b-th>
                <b-th>Views</b-th>
                <b-th>Clicks</b-th>
                <b-th>Click-Through Rate</b-th>
                <b-th v-if="showConversions">Conversions</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="stats === null">
              <b-tr v-for="i in 10" v-bind:key="i">
                <b-th>
                  <Loader :newStyle="1" />
                </b-th>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td>
                  <Loader :newStyle="1" />
                </b-td>
                <b-td v-if="showConversions">
                  <Loader :newStyle="1" />
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr v-for="(keyword, index) in formattedKeywords" v-bind:key="index">
                <b-th v-if="keyword.keyword_original == ''">
                  AI generated keywords
                  <v-chip color="bg-success text-white" class="ma-2" x-small>AI</v-chip>
                </b-th>
                <b-th v-else>{{ keyword.keyword_original }} </b-th>

                <b-td v-if="keyword.keyword_original == ''"><span class="text-success" style="font-weight: 500">+{{
                    keyword.impressions
                }}</span></b-td>
                <b-td v-else>{{ keyword.impressions }}</b-td>

                <b-td v-if="keyword.keyword_original == ''"><span class="text-success" style="font-weight: 500">+{{
                    keyword.clicks
                }}</span></b-td>
                <b-td v-else>{{ keyword.clicks }}</b-td>

                <b-td v-if="keyword.keyword_original == ''"><span class="text-success" style="font-weight: 500">+{{
                    (keyword.ctr * 100).toFixed(0)
                }}%</span></b-td>
                <b-td v-else>{{ (keyword.ctr * 100).toFixed(0) }}%</b-td>

                <template v-if="showConversions">
                  <b-td v-if="keyword.keyword_original == ''"><span class="text-success" style="font-weight: 500">+{{
                      keyword.conversions
                  }}</span></b-td>
                  <b-td v-else>{{ keyword.conversions }}</b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
//import ApiService from "@/common/api.service";

export default {
  components: {
    KTPortlet,
    Loader,
    apexcharts: VueApexCharts
  },
  watch: {},
  data() {
    return {
      showConversions: false,
      stats: null,
      usage: null,
      start: null,
      end: null,
      chartOptions: {
        annotations: {
          xaxis: [
          ]
        },
        colors: ["#0091ea", "#6200ea", "#00c853", "#ffd600"],
        chart: {
          type: "area",
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },

        xaxis: {
          labels: {
            formatter: function(value, timestamp) {
              return moment(new Date(timestamp)).format("DD/MM/YYYY");
            }
          },
          type: "datetime",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            /*formatter: function(value) {
              return (value * (value < 0 ? -1 : 1)).toFixed(2) + " GB";
            },*/
            offsetY: -7,
            offsetX: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "datetime"
          },
          fixed: {
            enabled: false,
            position: "topRight"
          }
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      },
      suggestion: null,
      knownAds: null
    };
  },
  methods: {
    getRelatedAd(headline1, headline2, description) {
      if (this.knownAds === undefined) return null;
      if (this.knownAds === null) return null;
      let old = false;
      for (let adSet of this.knownAds) {
        for (let ad of adSet.ads) {
          if (ad.headline1 == headline1 && ad.headline2 == headline2 && ad.description1 == description) {
            return {ad, old};
          }
        }
        old = true;
      }
      return null;
    },
    updateService(serviceId) {
      let self = this;
      if (self.$data.stats === -1) return;
      self.$data.stats = null;
      /*self.$data.suggestion = null;
      TLib.getAdBudSuggestions(self, serviceId).then(sugg => {
        if (sugg.description === undefined) return;
        if (sugg.completed === true) return;
        self.$data.suggestion = sugg;
      }).catch(self.$log.error);*/
      TLib.getAdBudStats(self, serviceId, -1)
        .then(async z => {
          let adss = (await TLib.getAdBudADsList(self, serviceId, undefined, 10));
          if (adss.length > 0)
            self.$data.knownAds = adss;
          self.$data.stats = z.data;
          self.$data.start = z.start;
          self.$data.end = z.end;

          TLib.getAdBudADsList(self, serviceId).then(xList => {
            self.$refs.mainChart.clearAnnotations();
            if (xList.length < 2) return;
            for (let adIndex = xList.length - 1; adIndex >= 0; adIndex--) {
              console.log('add:');
              //for (let listItem of xList) {
              //if (xList[adIndex].date === 0) continue;
              //self.$data.chartOptions.annotations.xaxis.push({
              self.$refs.mainChart.addXaxisAnnotation({
                x: xList[adIndex].date,
                //x2: adIndex === 0 ? 0 : xList[adIndex-1].date,
                fillColor: '#B3F7CA',
                label: {
                  text: 'Advert update'
                }
                /*borderColor: '#999',
                //yAxisIndex: 0,
                label: {
                  show: true,
                  text: 'Rally',
                  style: {
                    color: "red",
                    background: '#775DD0'
                  }
                }*/
              });
            }
            //window.test = self.$refs.mainChart;
            //self.$refs.mainChart.refresh();
          })
            .catch(self.$log.error);
        })
        .catch(self.$log.error);
      // return;
      // self.updateUsage(serviceId);
      // self.$data.service = null;
      // TLib.getSIM(this, serviceId)
      //   .then(x => {
      //     self.$nextTick(() => {
      //       self.$data.service = x;
      //     });
      //   })
      //   .catch(x => {
      //     self.$log.error(x);
      //     self.$log.error("Error loading content");
      //   });
    }
    /*updateUsage(serviceId) {
      let self = this;
      self.$data.usage = null;
      TLib.getUsage(this, serviceId)
        .then(x => {
          self.$nextTick(() => {
            self.$data.usage = x;
          });
        })
        .catch(x => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    }*/
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    fstart() {
      return moment(this.start).format("DD/MM/YYYY");
    },
    fend() {
      return moment(this.end).format("DD/MM/YYYY");
    },

    formattedAds() {
      if (this.$tools.isNullOrUndefined(this.stats)) return [];
      if (this.$tools.isNullOrUndefined(this.stats.total)) return [];
      if (this.$tools.isNullOrUndefined(this.stats.total.ads)) return [];
      let aiBasedAd = null;
      let returnData = [];
      for (let ad of this.stats.total.ads) {
        if (ad.ad_type === "EXPANDED_DYNAMIC_SEARCH_AD") {
          if (this.$tools.isNullOrUndefined(aiBasedAd)) aiBasedAd = ad;
          else {
            aiBasedAd.clicks += ad.clicks;
            aiBasedAd.conversions += ad.conversions;
            aiBasedAd.cpc += ad.cpc;
            aiBasedAd.ctr += ad.ctr;
            aiBasedAd.impressions += ad.impressions;
          }
        } else returnData.push(ad);
      }
      if (!this.$tools.isNullOrUndefined(aiBasedAd))
        returnData.unshift(aiBasedAd);
      return returnData;
    },
    formattedKeywords() {
      if (this.$tools.isNullOrUndefined(this.stats)) return [];
      if (this.$tools.isNullOrUndefined(this.stats.total)) return [];
      if (this.$tools.isNullOrUndefined(this.stats.total.keywords)) return [];
      let aiBasedAd = null;
      let returnData = [];
      for (let ad of this.stats.total.keywords) {
        if (ad.keyword_original === "") {
          if (this.$tools.isNullOrUndefined(aiBasedAd)) aiBasedAd = ad;
          else {
            aiBasedAd.clicks += ad.clicks;
            aiBasedAd.conversions += ad.conversions;
            aiBasedAd.cpc += ad.cpc;
            aiBasedAd.ctr += ad.ctr;
            aiBasedAd.impressions += ad.impressions;
          }
        } else returnData.push(ad);
      }
      if (!this.$tools.isNullOrUndefined(aiBasedAd))
        returnData.unshift(aiBasedAd);
      return returnData;
    },
    keywordSearches() {
      let numC = 0;
      for (let keyw of this.stats.total.account_daily) {
        numC += keyw.impressions;
      }
      for (let keyw of this.stats.total.keywords) {
        numC += keyw.impressions;
      }
      for (let keyw of this.stats.total.ads) {
        numC += keyw.impressions;
      }
      for (let keyw of this.stats.total.campaigns) {
        numC += keyw.impressions;
      }
      return numC + this.stats.total.account.impressions;
      /*if (this.$tools.isNullOrUndefined(this.stats)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.keywords)) return null;

      let numC = 0;
      for (let keyw of this.stats.total.keywords) {
        numC += keyw.impressions;
      }
      return numC;*/
    },

    chartData() {
      let data = [
        {
          name: "Clicks",
          data: []
        },
        /*{
          name: "Calls",
          data: []
        },*/
        {
          name: "Impressions",
          data: []
        }
      ];

      if (this.showConversions) {
        data.push({
          name: "Conversions",
          data: []
        });
      }

      if (this.$tools.isNullOrUndefined(this.stats)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.total)) return null;
      if (this.$tools.isNullOrUndefined(this.stats.total.account_daily)) return null;

      for (let itemI of Object.keys(this.stats.total.account_daily)) {
        let item = this.stats.total.account_daily[itemI];
        data[0].data.push({
          x: item.date,
          y: item.clicks
        });
        data[1].data.push({
          x: item.date,
          y: item.impressions
        });
        /*data[2].data.push({
          x: item.date,
          y: item.impressions
        });*/
        if (this.showConversions) {
          data[2].data.push({
            x: item.date,
            y: item.conversions
          });
        }
      }

      return data;
    }
  },
  beforeDestroy() {
    this.$data.stats = -1;
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Advertising" },
      { title: "Stats" }
    ]);

    if (
      !this.$tools.isNullOrUndefined(this.config.charts) &&
      !this.$tools.isNullOrUndefined(this.config.charts.colours)
    )
      this.$data.chartOptions.colors = this.config.charts.colours;

    this.updateService(this.$route.params.serviceId);

    let self = this;

    self.$eventBus.on(`switch-service-selected`, x => self.updateService(x));
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  }
};
</script>
