//const gatewaysCacheKey = 'betternetworks:gateways';
//const devicesCacheKey = 'betternetworks:devices';
//let VM;

import moment from "moment";

export default {
  initUpdator(/*self */) {
    //console.log('initUpdator')
    //let me = this;
    //VM = this;
    /*self.$eventBus.on( "ws-networks-device-update", async ( updateData ) => {
      await me.getDevices( self, null, true );
      return self.$eventBus.emit( 'networks-device-update', updateData );
    } );
    self.$eventBus.on( "ws-networks-templates-update", ( updateData ) => {
      //console.log('UPDATE TRIGGER: ' + updateData.id);
      me.getTemplates( self, null, true ).then( () => {} ).catch( () => {} );
      me.getTemplates( self, updateData.id, true ).then( () => {} ).catch( () => {} );
      if ( !self.$tools.isNullOrUndefined( updateData.draftVersion ) ) {
        //console.log('UPDATE TRIGGER: ' + `${updateData.id}.${updateData.draftVersion}`);
        me.getTemplates( self, `${updateData.id}.${updateData.draftVersion}`, true ).then( () => {} ).catch( () => {} );
      }
    } );
    self.$eventBus.on( "ws-networks-gateway-syslog-result", syslog => {
      let syslogList = [];
      let sysLogData = window.sessionStorage.getItem( 'syslogs' );
      if ( !self.$tools.isNullOrUndefined( sysLogData ) ) {
        syslogList = JSON.parse( sysLogData );
      }
      me.getDevices( self )
        .then( deviceList => {
          for ( let device of deviceList ) {
            if ( device.id !== syslog.deviceId ) continue;
            return self.$eventBus.emit( 'notification', {
              value: {
                title: syslog.result.message,
                color: syslog.result.message.indexOf( 'error' ) < 0 ? 'info' : 'danger',
                icon: 'flaticon-layer',
                postNote: device.name,
                link: `/Networks/Syslog/${syslog.gatewayId}-${syslog.deviceId}-${syslog.result.date}-${syslog.result.gatewayTime}`
              },
              time: new Date( syslog.result.date ).getTime(),
              type: 'events'
            } );
          }
        } );

      syslogList.push( syslog );

      if ( syslogList.length > 100 ) {
        syslogList.splice( 100 );
      }
      window.sessionStorage.setItem( 'syslogs', JSON.stringify( syslogList ) );

      self.$eventBus.emit( 'gateway-syslog', syslog );
    } );*/
  },
  getAdBudStats: (self, serviceId, negMonths = -1) =>
    new Promise((resolve, reject) => {
      //console.log(`${now}`)
      let end =
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(-1, "days")
          .unix() * 1000;
      let start =
        moment()
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .add(negMonths, "months")
          .unix() * 1000;
      //getWithCache(self, path1, path2, cacheKey, bumpCache = false) {
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/stats?start=${start}&end=${end}`,
          `adbud-ads-stats-${self.$root.clientId}-${serviceId}-${start}-${end}`
        )
        .then((x) =>
          resolve({
            start,
            end,
            data: x,
          })
        )
        .catch(reject);
    }),
  getAdBudADsList: (self, serviceId, forced = false, limit = 50) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/list/${limit}`,
          `adbud-ads-list-${self.$root.clientId}-${serviceId}-`,
          forced
        )
        .then((x) => resolve(x))
        .catch(reject);
    }),
  getAdBudClient: (self, serviceId, forced = false) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/me`,
          `adbud-ads-stats-${self.$root.clientId}-${serviceId}-`,
          forced
        )
        .then((x) => resolve(x))
        .catch(reject);
    }),
  getAdBudSuggestions: (self, serviceId, forced = false) =>
    new Promise((resolve, reject) => {
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/suggestion`,
          `adbud-suggestion-${self.$root.clientId}-${serviceId}-`,
          forced
        )
        .then((x) => resolve(x))
        .catch(reject);
    }),
  setAdBudClient: (self, serviceId, itemsObjectToSet) =>
    new Promise((resolve, reject) => {
      self.$cache.del(`adbud-ads-stats-${self.$root.clientId}-${serviceId}-`);
      self.$api
        .update2(
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/me`,
          undefined,
          itemsObjectToSet
        )
        .then((x) => {
          if (x.status === 202) return resolve(x);
          reject(x.data);
        })
        .catch(reject);
    }),
  getAdBudTerms: (self, serviceId) =>
    new Promise((resolve, reject) => {
      self.$api
        .get(`advertising`, `${self.$root.clientId}/${serviceId}/adbud/terms`)
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  setAdBudTerm: (self, serviceId, termId, wanted) =>
    new Promise((resolve, reject) => {
      /*setTimeout(()=>{
        resolve({term, wanted})
      }, 2000)*/
      self.$api
        .update2(
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/terms/${termId}`,
          undefined,
          {
            wanted,
          }
        )
        .then((x) => resolve(x.data))
        .catch(reject);
    }),
  searchGeoLocation: (self, serviceId, country, term) =>
    new Promise((resolve, reject) => {
      /*setTimeout(()=>{
        resolve({term, wanted})
      }, 2000)*/
      self.$api
        .getWithCache(
          self,
          `advertising`,
          `${self.$root.clientId}/${serviceId}/adbud/geotarget/${country}/${term}`,
          `adbud-ads-geotarget-${self.$root.clientId}-${serviceId}-${country}-${term}`
        )
        .then((x) => resolve(x))
        .catch(reject);
    }),
};
